// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$primary-palette: (
  50: #daf2ff,
  100: #b9dced,
  200: #9bc2d7,
  300: #7aa8c0,
  400: #6193ae,
  500: #46809d,
  600: #39718b,
  700: #2a5d74,
  800: #1c4a5f,
  900: #073547,
  contrast: (
    50: rgba(#000, 0.87),
    100: rgba(#000, 0.87),
    200: rgba(#000, 0.87),
    300: rgba(#000, 0.87),
    400: rgba(#000, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$secondary-palette: (
  50: #e9f4fa,
  100: #c3e5f9,
  200: #99d6fb,
  300: #6cc5fc,
  400: #45b8fe,
  500: #11acff,
  600: #009df2,
  700: #008adf,
  800: #0079ce,
  900: #005bb1,
  contrast: (
    50: rgba(#000, 0.87),
    100: rgba(#000, 0.87),
    200: rgba(#000, 0.87),
    300: rgba(#000, 0.87),
    400: rgba(#000, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$accent-palette: (
  50: #feebf0,
  100: #fdcdd7,
  200: #ee9aa3,
  300: #e4727f,
  400: #f15060,
  500: #f73b49,
  600: #e83247,
  700: #d62840,
  800: #c92139,
  900: #ba122d,
  contrast: (
    50: rgba(#000, 0.87),
    100: rgba(#000, 0.87),
    200: rgba(#000, 0.87),
    300: rgba(#000, 0.87),
    400: rgba(#000, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$neutral-palette: (
  50: #f3f8ff,
  100: #ebf1fe,
  200: #e1e7f4,
  300: #d0d6e3,
  400: #acb2be,
  500: #8c919d,
  600: #646975,
  700: #515661,
  800: #333842,
  900: #131821,
  contrast: (
    50: rgba(#000, 0.87),
    100: rgba(#000, 0.87),
    200: rgba(#000, 0.87),
    300: rgba(#000, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$project-primary: mat.define-palette($primary-palette);
$project-secondary: mat.define-palette($secondary-palette);
$project-neutral: mat.define-palette($neutral-palette);
$project-accent: mat.define-palette($neutral-palette, 900);

// The warn palette is optional (defaults to red).
$project-warn: mat.define-palette($accent-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$project-theme: mat.define-light-theme((
  color: (
    primary: $project-primary,
    accent: $project-accent,
    warn: $project-warn,
    secondary: $project-secondary,
    neutral: $project-neutral,
  )
));

@mixin setVar($name, $value) {
  #{$name}: $value;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($project-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  @include setVar('--neutral-900', mat.get-color-from-palette($project-neutral, 900));
  @include setVar('--neutral-900-contrast', mat.get-color-from-palette($project-neutral, '900-contrast'));
}