/* You can add global styles to this file, and also import other style files */
app-login
    width: 100%


//aside-custom
.mat-expansion-indicator::after
    color: white !important
.mat-expansion-panel-body
    padding: 0px !important
.aside-scroll-custom
    ::-webkit-scrollbar 
        width: 5px
    
    ::-webkit-scrollbar-track 
        background: #21252C
        width: 12px
    ::-webkit-scrollbar-thumb 
        border-radius: 10px
        background: var(--neutral-900)


.custom-aside-menu
    position: absolute
    left: 54px
    top: -140px
    background-color: #21252C !important    
    .mat-mdc-menu-item:hover
        transition: all 0.4s
        background-color: var(--neutral-900) !important
          
.custom-aside-menu *
    color: white !important

.municipality-opcion
    font-size: 14px !important
mat-datepicker-content
    transform: scale(0.91) !important
    









